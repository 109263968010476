import config from "./config.json";
import data_ranges from "./data/institution_data_ranges.json";

export const setScopeRecursively = function(targetEl, scopeId) {
  // This function will recursively travel down from the targetEl and apply the scopeId as a data attribute for CSS scoping.
  // scopeId should be a string (e.g. v-123f1123a)
  try {
    targetEl.setAttribute(`data-${scopeId}`,"")
  } catch(e) {
    console.log(e)
  }

  // Recursively call setScopeRecursively for any children.
  if (targetEl.hasChildNodes()) {
    const list = targetEl.children
    for (var item of list) {
      setScopeRecursively(item, scopeId)
    }
  }
}

export function updateScopeId(el, __, vNode) {
  const vm = vNode.context
  const scopeId = vm & vm.$options._scopeId
  if (scopeId) {
    vm.$nextTick(() => { // wait till DOM was updated
      setScopeRecursively(el, `v-${scopeId}`)
    })
  }
}

export const handleErrors = function(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}


export const getCookie = function(cookie_name) {
  let name = cookie_name + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const setCookie = function(cookie_name, cookie_value, ex_days) {
  let d = new Date();
  d.setTime(d.getTime() + (ex_days*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cookie_name + "=" + cookie_value + ";" + expires + ";path=/";
}


export const formatStat = function(num, label) {
  if (label.indexOf('pct') !== -1 || label.indexOf('percent') !== -1) {
    return `${num}%`
  }
  else {
    return num
  }
}

export const createCSV = function(data, add_filetype_prefix) {
  if (typeof add_filetype_prefix === 'undefined') {
    add_filetype_prefix = true;
  }
  let recursive_add_header = function(d, header, prefix) {
    if (typeof prefix === "undefined") {
      prefix = '';
    }
    else {
      prefix = prefix + "__"
    }
    if (typeof header === "undefined") {
      header = [];
    }
    Object.keys(d).forEach(key => {
      if (typeof d[key] === "object") {
        recursive_add_header(d[key], header, key)
        return
      }
      header.push(prefix + key)
    })
    return header;
  }
  let header = recursive_add_header(data[0])

  let recursive_flatten_array = function(d, row, prefix) {
    if (typeof prefix === "undefined") {
      prefix = '';
    }
    else {
      prefix = prefix + "__"
    }
    if (typeof row === "undefined") {
      row = [];
    }
    Object.keys(d).forEach(key => {
      if (typeof d[key] === "object") {
        row = recursive_flatten_array(d[key], row, key)
        return
      }
      row[prefix + key] = d[key]
    })
    return row
  }

  const rows = data.map(i => {
    let row = recursive_flatten_array(i);
    return header.map(k => '"' + (row[k] + "").replace('"', '""') + '"');
  });

  let ret = "";
  if (add_filetype_prefix) {
    ret += "data:text/csv;charset=utf-8,"
  }
  ret += header.join(",") + "\n"
    + rows.map(e => e.join(",")).join("\n");
  return ret;
}

const d3 = require('d3');

export const addGraphs = function(data, ipeds, data_section, options) {
  if (typeof options === 'undefined') {
    options = {};
  }
  console.log('data', data, 'ipeds', ipeds, 'data_section', data_section)

  let text_line_height = config.graphs.text_line_height;

  if ('inline_labels' in options && options['inline_labels']) {
    text_line_height = 0;
  }
  let margin = config.graphs.padding,
    text_size = config.graphs.text_size,
    width = config.graphs.width - margin.left - margin.right,
    bar_height = config.graphs.bar_height,
    bar_section_height = text_line_height + config.graphs.bar_height + config.graphs.between_bars,
    height = bar_section_height * data.length,
    starting_y = 0,
    text_y_offset = config.graphs.text_line_height / 2;


  if ('overall_label' in options && options['overall_label']) {
    height += config.graphs.text_line_height;
    starting_y = config.graphs.text_line_height;
  }

  const d3_selector = `institution-data__graph--${data.map(d => d['key']).join('-')}--${ipeds}`

  this.$nextTick(function() {
    document.querySelector('.' + d3_selector).innerHTML = "";

    let graph_svg = d3.select("." + d3_selector).append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)

    let g = graph_svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    if ('overall_label' in options && options['overall_label']) {
      g.append('text')
        .attr("x", 0)
        .attr("y", text_y_offset)
        .attr("dy", "0.32em")
        .attr("fill", "#333")
        .attr("font-size", text_size + "px")
        .attr("line-height", text_line_height + "px")
        .attr("text-anchor", "start")
        .text(() => options['overall_label']);
    }

    const bar_data_padding = 4; // space between end of bar and text;

    const format_percentage = (val) => {
      if (val < 1) {
        val = val * 100;
      }
      // round to 2 decimal places
      val = Math.round(val * 100) / 100;
      return val + "%"
    }
    const format_val = (val) => {
      return Number(val).toLocaleString()
    }
    const format_currency = (val) => {
      return "$" + Number(val).toLocaleString()
    }
    const data_format_function = (val, key, data_section) => {
      let r
      if (val === -1 || val === "-1") {
        r = "N/A";
      }
      else if (key.indexOf('pct') !== -1 || key.indexOf('percent') !== -1) {
        r = format_percentage(val)
      }
      else {
        if (key.indexOf('budget') !== -1 || key.indexOf('endow') !== -1) {
          r = format_currency(val)
        }
        else {
          r = format_val(val)
        }
      }
      if ('inline_labels' in options && options['inline_labels']) {
        r += " " + this.$i18n.t('data-labels.' + data_section + '.' + key)
      }
      return r;
    }

    const get_width_of_text = (text) => {
      let tmp = document.createElement("span");
      tmp.innerText = text;
      tmp.style.fontSize = '12px';
      tmp.style.fontWeight = 'bold';
      tmp.style.fontFamily = 'Montserrat';
      tmp.style.padding = text_y_offset + "px"
      tmp.style.opacity = '0';
      document.body.append(tmp)
      let element_width = tmp.getBoundingClientRect().width;
      document.body.removeChild(tmp)
      return element_width
    }

    const get_text_x_pos = (value, key, x, data_section) => {
      let text_width = get_width_of_text(data_format_function(value, key, data_section))
      let bar_width = x(value)
      if (bar_width > text_width + bar_data_padding * 2) {
        return bar_data_padding
      }
      else {
        return bar_width + bar_data_padding
      }
    }
    const get_text_color = (value, key, x, data_section) => {
      let text_width = get_width_of_text(data_format_function(value, key, data_section))
      let bar_width = x(value)
      if (bar_width > text_width + bar_data_padding * 2) {
        return config.colors.text_on_teal;
      }
      else {
        return config.colors.teal;
      }
    }

    data.forEach((data_item, index) => {
      let stat = g.append("g")

      // console.log(data_ranges[data_section])
      let x = d3.scaleLinear()
        .domain(data_ranges[data_section][data_item['key']]).nice()
        .range([0, width]);

      if (!('inline_labels' in options && options['inline_labels'])) {
        stat.append('text')
          .attr("x", 0)
          .attr("y", () => (index * bar_section_height) + starting_y + text_y_offset)
          .attr("dy", "0.32em")
          .attr("fill", "#333")
          .attr("font-size", text_size + "px")
          .attr("line-height", text_line_height + "px")
          .attr("text-anchor", "start")
          .text(() => this.$i18n.t('data-labels.' + data_section + '.' + data_item['key']));
      }
      stat.append("rect")
        .attr("x", 0)
        .attr("y", (index * bar_section_height) + text_line_height + starting_y)
        .attr("width", () => x(data_item['data']))
        .attr("height", bar_height)
        .attr("fill", config.colors.teal)
      stat.append('text')
        .attr("x", () => get_text_x_pos(data_item['data'], data_item['key'], x, data_section))
        .attr("y", () => (index * bar_section_height) + text_line_height + starting_y + (bar_height / 2))
        .attr("dy", "0.32em")
        .attr("fill", () => get_text_color(data_item['data'], data_item['key'], x, data_section))
        .attr("font-weight", "bold")
        .attr("font-size", "12px")
        .attr("text-anchor", "start")
        .text(() => data_format_function(data_item['data'], data_item['key'], data_section));
    });
    // console.log(g);
  })

  let filename = `${ipeds}__graph__${data_section}__${data.map(d => d['key']).join('-')}.svg`;
  return `<div class="institution-data__graph ${d3_selector}" data-ipeds="${ipeds}" data-filename="${filename}"></div>`
}
