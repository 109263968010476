<template>
  <div class="institution-data__mini-block institution-data__mini-block--financial">
    <h5 class="institution-data__mini-title">{{ $t('data-labels.block-titles.financial') }}</h5>
    <div class="institution-data__mini-stats">
      <div class="institution-data__mini-stat" v-html="addGraphs(['fte_staff', 'percent_rev_tuition', 'percent_rev_state'].map((s) => {return {'key': s, 'data': institution.financial[s]}}), institution.ipeds, 'financial')">
      </div>
    </div>
    <div class="institution-data__mini-stats">
      <div class="institution-data__mini-stat" v-html="addGraphs(['percent_exp_instruct', 'endow_per_fte'].map((s) => {return {'key': s, 'data': institution.financial[s]}}), institution.ipeds, 'financial')">
      </div>
    </div>
  </div>

</template>

<script>
import {addGraphs} from "../utilities";

export default {
  name: 'financial-block',
  props: ['institution'],
  methods: {
    addGraphs: addGraphs,
  }
}
</script>

<style scoped lang="scss">

.institution-data__mini-block {
  display: flex;
  flex-flow: row wrap;
}

.institution-data__mini-title {
  padding-left: 4px;
  flex-basis: 100%;
}

.institution-data__mini-stats {
  flex: 1 1 150px;
}

</style>
