var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"institution-data__mini-block institution-data__mini-block--student-body"},[_c('h5',{staticClass:"institution-data__mini-title"},[_vm._v(_vm._s(_vm.$t('data-labels.block-titles.student_body')))]),_c('div',{staticClass:"institution-data__mini-stats"},[_c('div',{staticClass:"institution-data__mini-stat",domProps:{"innerHTML":_vm._s(_vm.addGraphs(['enrolled_total',
         'fte_students', 'undergrad_enrollment',
         'graduate_enrollment', 'percent_pell'].map(function (s) {
           return {'key': s, 'data': _vm.institution.student_body[s]}
         }), _vm.institution.ipeds, 'student_body'))}}),_c('div',{staticClass:"institution-data__mini-stat",domProps:{"innerHTML":_vm._s(_vm.addGraphs(['percent_race_white', 'percent_race_black', 'percent_race_asian', 'percent_race_hawaiian_pacific', 'percent_race_native', 'percent_race_hispanic', 'percent_race_two_or_more', 'percent_race_unknown'].map(function (s) {
           return {'key': s, 'data': _vm.institution.student_body[s]}
         }), _vm.institution.ipeds, 'student_body', {'inline_labels': true, 'overall_label': _vm.$t('data-labels.student_body.race_ethnicity')}))}}),_c('div',{staticClass:"institution-data__mini-stat",domProps:{"innerHTML":_vm._s(_vm.addGraphs(['percent_adult'].map(function (s) {
           return {'key': s, 'data': _vm.institution.student_body[s]}
         }), _vm.institution.ipeds, 'student_body'))}})])])}
var staticRenderFns = []

export { render, staticRenderFns }