<template>
  <div class="institution-data__mini-block institution-data__mini-block--rsi">
    <h5 class="institution-data__mini-title">{{ $t('data-labels.block-titles.rsi') }}</h5>
    <div class="institution-data__mini-stats">
      <div class="institution-data__mini-stat" v-for="stat in ['rsi_score', 'percent_ag_res_rec', 'county_pct_rural', 'cluster_pct_rural_avg', 'modified_rucc']" :key="stat">
        <span class="institution-data__mini-stat-value">{{ formatStat(institution.rsi[stat], stat) }}</span>
        <span class="institution-data__mini-stat-label"> {{ $t('data-labels.rsi.' + stat) }}</span>
      </div>
      <div class="institution-data__mini-stat">
        <span class="institution-data__mini-stat-value">{{ $t("data-labels.rsi.not_adjacent_urban_" + institution.rsi['not_adjacent_urban']) }}</span>
        <span class="institution-data__mini-stat-label"> {{ $t('data-labels.rsi.not_adjacent_urban') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {formatStat} from "../utilities";

export default {
  name: 'rsi-block',
  props: ['institution'],
  methods: {
    formatStat: formatStat
  }
}
</script>
