<template>
  <div class="institution-data__mini-block institution-data__mini-block--overview">
    <h5 class="institution-data__mini-title">{{ $t('data-labels.block-titles.overview') }}</h5>
    <div class="institution-data__mini-stats">
      <div class="institution-data__mini-stat" v-for="stat in ['sector', 'carnegie', 'highest_degree_awarded']" :key="stat">
        <div class="institution-data__mini-stat-label"> {{ $t('data-labels.overview.' + stat) }}</div>
        <div class="institution-data__mini-stat-value">{{ getLabel(institution.overview[stat], stat) }}</div>
      </div>

      <div class="institution-data__mini-stat">
        <div class="institution-data__mini-stat-label"> {{ $t('data-labels.overview.institutional_designation') }}</div>
        <div class="institution-data__mini-stat-value">
          <div v-for="stat in ['hbcu','tribal','landgrnt','highhispan', 'highblack', 'highnative', 'highaapsi']" :key="stat">
            <span class="institution-data__mini-stat-sub-value" v-if="institution.overview[stat] == 1">{{ $t('data-labels.overview.' + stat) }}</span>
          </div>
          <span class="institution-data__mini-stat-sub-value institution-data__mini-stat-sub-value--none" v-if="!['hbcu','tribal','landgrnt','highhispan', 'highblack', 'highnative', 'highaapsi'].some(stat => institution.overview[stat] == 1)">{{ $t('data-labels.overview.institutional_designation_none') }}</span>
        </div>
      </div>

      <div class="institution-data__mini-stat">
        <div class="institution-data__mini-stat-label"> {{ $t('data-labels.overview.degrees_awarded') }}</div>
        <div class="institution-data__mini-stat-value">
          <div v-for="stat in ['undergradcertificates', 'associatesawarded', 'bachelorsawarded', 'graduatecertificates', 'mastersawarded', 'doctoratesawarded']" :key="stat">
            <span class="institution-data__mini-stat-sub-value" v-if="institution.overview[stat] == 1">{{ $t('data-labels.overview.' + stat) }}</span>
          </div>
          <span class="institution-data__mini-stat-sub-value institution-data__mini-stat-sub-value--none" v-if="!['undergradcertificates', 'associatesawarded', 'bachelorsawarded', 'graduatecertificates', 'mastersawarded', 'doctoratesawarded'].some(stat => institution.overview[stat] == 1)">{{ $t('data-labels.overview.institutional_designation_none') }}</span>
        </div>
      </div>
      <div class="institution-data__mini-stat">
        <div class="institution-data__mini-stat-label"> {{ $t('data-labels.overview.county_traits') }}</div>
        <div class="institution-data__mini-stat-value">
          <div v-for="stat in ['lowed', 'lowemp', 'poploss', 'perpov', 'perchldpov']" :key="stat">
            <span class="institution-data__mini-stat-sub-value" v-if="institution.overview[stat] == 1">{{ $t('data-labels.overview.' + stat) }}</span>
          </div>
          <span class="institution-data__mini-stat-sub-value institution-data__mini-stat-sub-value--none" v-if="!['lowed', 'lowemp', 'poploss', 'perpov', 'perchldpov'].some(stat => institution.overview[stat] == 1)">{{ $t('data-labels.overview.county_traits_none') }}</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {formatStat} from "../utilities";
import value_labels from '../data/value_labels.json'

export default {
  name: 'overview-block',
  props: ['institution'],
  methods: {
    formatStat: formatStat,
    getLabel: function(key, type) {
      if (type === 'carnegie') {
        type = 'full_carnegie'
      }
      return value_labels[type][key]
    }
  }
}
</script>


<style scoped lang="scss">

.institution-data__mini-stat-label {
  font-weight: bold;
}
.institution-data__mini-stat-sub-value--none {
  opacity: 0.7;
}

</style>

