<template>
  <div class="rural-univ-map" :style="cssImageVars">
    <l-map class="rural-univ-map__map" ref="rural_univ_map"
           :zoomControl="leaflet_settings.zoomControl"
           :zoomSnap="leaflet_settings.zoomSnap">
      <l-tile-layer
          :url="leaflet_settings.tileLayer.url"
          :subdomains="leaflet_settings.tileLayer.subdomains"
          :attribution="leaflet_settings.tileLayer.attribution"
          :maxZoom="leaflet_settings.tileLayer.maxZoom"
      ></l-tile-layer>
      <vue2-leaflet-marker-cluster
      :options="{polygonOptions: {opacity: 0, fillOpacity: 0},
      iconCreateFunction: clusterIconCreateFunction
	}"

      >
        <l-marker @click="activateInstitution(institution.ipeds, null)"
                  @mouseover="open_ipeds_inst_name_hover = institution.ipeds"
                  @mouseleave="open_ipeds_inst_name_hover = null"
                  v-for="institution in filtered_results" :key="institution.ipeds" :lat-lng="latlng(institution.meta.latitude, institution.meta.longitude)">
          <l-icon
              :icon-anchor="(institution.ipeds === active_ipeds || institution.ipeds === active_compare_ipeds ? leaflet_settings.icon_size.active : leaflet_settings.icon_size.default ).map(i => i / 2) "
              :class-name="'institution-icon institution-icon--' + (institution.overview.sector == 2 ? 'private' : 'public') + (institution.ipeds === active_ipeds ? ' institution-icon--active-institution' : '' ) + (institution.ipeds === active_compare_ipeds ? ' institution-icon--active-compare-institution' : '' )  "
              :icon-size="(institution.ipeds === active_ipeds || institution.ipeds === active_compare_ipeds ? leaflet_settings.icon_size.active : leaflet_settings.icon_size.default )"
          >
            <span v-if="institution.overview.sector == 2 || institution.overview.sector == 1" v-html="4"></span>
            <span v-else-if="institution.overview.sector == 4 || institution.overview.sector == 5" v-html="2"></span>
            <span v-else>0</span>

          </l-icon>
          <l-tooltip :options="{'direction': 'bottom', offset: [0, 12]}" class="institution-icon__institution-name">{{ institution.meta.name }}</l-tooltip>

          <l-popup class="institution-icon__context-menu-button-wrapper" :options="{'minWidth': 310, 'maxWidth': 320, 'closeButton': false, className: 'institution-icon__context-menu', offset: [0, -6]}" ref="ipeds_context_menu" tabindex="0" @focusout="unfocusContextMenu">
            <button @click="activateInstitution(institution.ipeds, 'active')">{{ $t('compare-contexual-menu.replace-current') }}</button>
            <button @click="activateInstitution(institution.ipeds, 'compare')">
              {{ (active_compare_ipeds) ? $t('compare-contexual-menu.replace-compare') : $t('compare-contexual-menu.add-compare') }}
            </button>
          </l-popup>
        </l-marker>
      </vue2-leaflet-marker-cluster>
      <l-geo-json v-if="active_ipeds && current_adjacent_county_geojson.features && current_adjacent_county_geojson.features.length > 0"
                  :geojson="current_adjacent_county_geojson"
                  :options-style="styleGeoJson">
      </l-geo-json>
      <l-geo-json v-if="active_compare_ipeds && current_compare_adjacent_county_geojson.features && current_compare_adjacent_county_geojson.features.length > 0"
                  :geojson="current_compare_adjacent_county_geojson"
                  :options-style="styleGeoJsonCompare">
      </l-geo-json>
    </l-map>
    <helpPages :close_help_modal="close_help_modal" :modal_first_time="modal_first_time" v-model="disable_help_modal" v-if="show_help_modal"></helpPages>
    <div class="rural-univ-map__sidebar-wrapper">
      <div class="rural-univ-map__drawers">
        <div class="rural-univ-map__drawer rural-univ-map__drawer--list"
             :class="{'rural-univ-map__drawer--active': current_open_drawer==='list', 'rural-univ-map__drawer--other-active': current_open_drawer !== 'list' && current_open_drawer !== ''}">
          <button v-on:click="current_open_drawer = 'list'" class="rural-univ-map__drawer-tab"><span
              class="rural-univ-map__drawer-tab-icon"></span><span class="sr-only">List view</span></button>
          <div class="rural-univ-map__drawer-contents">
            <h3 class="rural-univ-map__drawer-title">{{ $t('tabs-list-view-title') }}
              <button v-on:click="current_open_drawer = ''; filters.text_search = ''">×</button>
            </h3>
            <div class="rural-univ-map__drawer-content results-list">
              <div class="results-list__search">
                <label></label>
                <input class="results-list__search-field" :placeholder="$t('begin-typing-search')" v-model="filters.text_search" />
              </div>
              <div class="results-list__help">
                {{ $t('tabs-list-help') }}
              </div>
              <div class="results-list__list">
                <div class="results-list__scrollable">
                  <button v-on:click="activateInstitution(institution.ipeds, 'active')" class="results-list__item" :class="{'results-list__item--active': active_ipeds === institution.ipeds, 'results-list__item--active-compare': active_compare_ipeds === institution.ipeds}" v-for="institution in filtered_results" :key="institution.ipeds">{{institution.meta.name}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rural-univ-map__drawer rural-univ-map__drawer--reset"
             :class="{'rural-univ-map__drawer--other-active': current_open_drawer !== ''}">
          <button v-on:click="resetFilters()" class="rural-univ-map__drawer-tab"><span
              class="rural-univ-map__drawer-tab-icon"></span><span class="sr-only">{{ $t('sr-text.reset-filters') }}</span></button>
        </div>
        <div class="rural-univ-map__drawer rural-univ-map__drawer--help"
             :class="{'rural-univ-map__drawer--active': current_open_drawer==='help', 'rural-univ-map__drawer--other-active': current_open_drawer !== 'help' && current_open_drawer !== ''}">
          <button v-on:click="show_help_modal = true" class="rural-univ-map__drawer-tab"><span
              class="rural-univ-map__drawer-tab-icon"></span><span class="sr-only">{{ $t('sr-text.help') }}</span></button>
        </div>
      </div>
      <div class="rural-univ-map__sidebar-border-outer-radius">
        <div class="rural-univ-map__sidebar-border-inner-radius">
          <div class="rural-univ-map__sidebar-border-inner-radius-background">
            <div class="rural-univ-map__sidebar">
              <div class="map-sidebar">
                <div class="map-sidebar__scrollable">
                  <div v-if="active_state==='filters'" class="map-sidebar__filters">
                    <h2 class="map-sidebar__title" :class="{'map-sidebar__title--padded': $i18n.locale === 'es'}">{{ $t('filters.title') }}</h2>
                    <locale-changer></locale-changer>
                    <div class="basic-filters">
                      <div class="filter filter--basic filter--rsi">
                        <label>{{ $t('filters.rsi') }}</label>
                        <span class="filter__slider">
                        <vue-slider
                            :min="data_ranges.rsi.rsi_score[0]"
                            :max="data_ranges.rsi.rsi_score[1]"
                            :interval="0.05"
                            :duration="0.1"
                            :contained="true"
                            :lazy="true"
                            :dragOnClick="true"
                            :tooltip="'always'"
                            :marks="[data_ranges.rsi.rsi_score[0], data_ranges.rsi.rsi_score[1]]"
                            v-model="filters.rsi"
                        />
                        </span>
                      </div>
                      <div class="filter filter--basic filter--sector">
                        <label>{{ $t('filters.sector') }}</label>
                        <div class="basic-filters__filter basic-filters__filter--sector-checkboxes">
                          <table>
                            <tr>
                              <td class="filter__table-checkbox">
                                <div class="legend-item basic-filters__filter basic-filters__filter--sector-checkbox">
                                  <input type="checkbox" id="sector-4" name="4" value="4" v-model="filters.sector">

                                  <label for="sector-4" class="legend-item__label">
                                    <div class="legend-item__swatch" :style="'background-color: ' + legend_items.find(li => li.id === 'two_year_public').color + ';'">
                                      {{ legend_items.find(li => li.id === 'two_year_public').inside_text }}
                                    </div>
                                    <div class="legend-item__text">
                                      {{ $t('filters.sector-2-pub') }}
                                    </div>
                                  </label>
                                </div>
                              </td>
                              <td v-html="$t('institution_count_displayed', {'count': filtered_results.filter(i => i.overview.sector == '4' ).length, 'total': total_institution_count['4'] })">
                              </td>
                            </tr>
                            <tr>
                              <td class="filter__table-checkbox">
                                <div class="legend-item basic-filters__filter basic-filters__filter--sector-checkbox">
                                  <input type="checkbox" id="sector-5" name="5" value="5" v-model="filters.sector">
                                  <label for="sector-5" class="legend-item__label">
                                    <div class="legend-item__swatch" :style="'background-color: ' + legend_items.find(li => li.id === 'two_year_private').color + ';'">
                                      {{ legend_items.find(li => li.id === 'two_year_private').inside_text }}
                                    </div>
                                    <div class="legend-item__text">
                                      {{ $t('filters.sector-2-pri') }}
                                    </div>
                                  </label>
                                </div>
                              </td>
                              <td v-html="$t('institution_count_displayed', {'count': filtered_results.filter(i => i.overview.sector == '5' ).length, 'total': total_institution_count['5'] })">
                              </td>
                            </tr>
                            <tr>
                              <td class="filter__table-checkbox">
                                <div class="legend-item basic-filters__filter basic-filters__filter--sector-checkbox">
                                  <input type="checkbox" id="sector-1" name="1" value="1" v-model="filters.sector">
                                  <label for="sector-1" class="legend-item__label">
                                    <div class="legend-item__swatch" :style="'background-color: ' + legend_items.find(li => li.id === 'four_year_public').color + ';'">
                                      {{ legend_items.find(li => li.id === 'four_year_public').inside_text }}
                                    </div>
                                    <div class="legend-item__text">
                                      {{ $t('filters.sector-4-pub') }}
                                    </div>
                                  </label>
                                </div>
                              </td>
                              <td v-html="$t('institution_count_displayed', {'count': filtered_results.filter(i => i.overview.sector == '1' ).length, 'total': total_institution_count['1'] })">
                              </td>
                            </tr>
                            <tr>
                              <td class="filter__table-checkbox">
                                <div class="legend-item basic-filters__filter basic-filters__filter--sector-checkbox">
                                  <input type="checkbox" id="sector-2" name="2" value="2" v-model="filters.sector">
                                  <label for="sector-2" class="legend-item__label">
                                    <div class="legend-item__swatch" :style="'background-color: ' + legend_items.find(li => li.id === 'four_year_private').color + ';'">
                                      {{ legend_items.find(li => li.id === 'four_year_private').inside_text }}
                                    </div>
                                    <div class="legend-item__text">
                                      {{ $t('filters.sector-4-pri') }}
                                    </div>
                                  </label>
                                </div>
                              </td>
                              <td v-html="$t('institution_count_displayed', {'count': filtered_results.filter(i => i.overview.sector == '2' ).length, 'total': total_institution_count['2'] })">
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td v-html="$t('institution_count_total', {'count': filtered_results.length, 'total': total_institution_count['total'] })">
                              </td>
                            </tr>
                          </table>

                        </div>
                      </div>
                      <div class="filter filter--basic filter--state">
                        <label>{{ $t('filters.state') }}</label>
                        <v-select :reduce="state => state.fipscode" :placeholder="$t('click-or-start-typing')"
                                  :options="state_options" v-model="filters.state" :multiple="true"></v-select>
                      </div>
                      <div class="filter filter--basic filter--enrollment-size">
                        <label>{{ $t('filters.enrollment-size') }}</label>
                        <span class="filter__slider">

                          <vue-slider
                              :min="logEnrollmentSizeOptions[0]"
                              :max="logEnrollmentSizeOptions.length - 1"
                              :interval="1"
                              :duration="0.1"
                              :contained="true"
                              :lazy="true"
                              :tooltip="'always'"
                              :marks="logEnrollmentSizeMarks"
                              :tooltip-formatter="getActualEnrollmentSize"
                              v-model="filters.enrollment_size"
                          />
                          </span>
                      </div>

                      <div class="filter filter--basic filter--degrees-awarded">
                        <label>{{ $t('filters.degrees-awarded') }}</label>
                        <v-select :reduce="opt => opt.key" :placeholder="$t('click-or-start-typing')"
                                  :options="degrees_awarded_options" v-model="filters.degrees_awarded" :multiple="true"></v-select>
                      </div>

                      <div class="filter filter--basic filter--highest-degree-awarded">
                        <label>{{ $t('filters.highest-degree-awarded') }}</label>
                        <v-select :reduce="opt => opt.key" :placeholder="$t('click-or-start-typing')"
                                  :options="highest_degree_awarded_options" v-model="filters.highest_degree_awarded"></v-select>
                      </div>


                    </div>
                    <details class="advanced-filters" open>
                      <summary>{{ $t('filters.advanced-settings') }}</summary>
                      <div class="advanced-filters__wrapper">
                        <div class="filter filter--advanced filter--percent-pell">
                          <label>{{ $t('filters.percent-pell') }}</label>
                          <span class="filter__slider">

                          <vue-slider
                              :min="config.ranges.percent_pell.min"
                              :max="config.ranges.percent_pell.max"
                              :interval="1"
                              :duration="0.1"
                              :contained="true"
                              :lazy="true"
                              :tooltip="'always'"
                              :marks="[config.ranges.percent_pell.min, config.ranges.percent_pell.max]"
                              :tooltip-formatter="percentFormatter"
                              v-model="filters.percent_pell"
                          />
                          </span>
                        </div>
                        <div class="filter filter--advanced filter--carnegie">
                          <label>{{ $t('filters.carnegie') }}</label>
                          <v-select :reduce="state => state.code" :placeholder="$t('click-or-start-typing')"
                                    :options="carnegie_options" v-model="filters.carnegie" :multiple="true"></v-select>
                        </div>
                        <div class="filter filter--advanced filter--institutional-designation">
                          <label>{{ $t('filters.institutional-designation') }}</label>
                          <v-select :reduce="desig => desig.code" :placeholder="$t('click-or-start-typing')"
                                    :options="institutional_designation_options" v-model="filters.institutional_designation"
                                    :multiple="true"></v-select>
                        </div>
                        <div class="filter filter--advanced filter--county-traits">
                          <label>{{ $t('filters.county-traits') }}</label>
                          <v-select :reduce="trait => trait.code" :placeholder="$t('click-or-start-typing')"
                                    :options="county_traits_options" v-model="filters.county_traits"
                                    :multiple="true"></v-select>
                        </div>
                      </div>
                    </details>
                    <a class="button button--download-csv-dataset button--download-csv-dataset--full" role="button" target="_blank" href="#" @click.prevent="downloadCSV('full')">{{ $t('download-csv-dataset-full') }}</a>
                    <a class="button button--download-csv-dataset button--download-csv-dataset--filtered" role="button" target="_blank" href="#" @click.prevent="downloadCSV('filtered')">{{ $t('download-csv-dataset-filtered') }}</a>
                    <a class="data-documentation" target="_blank" :href="config.data_documentation_link">{{ $t('view-data-documentation') }}</a>
                  </div>
                  <div v-if="active_state==='institution'" class="map-sidebar__institution">
                    <h2 class="map-sidebar__title">{{ $t('institution.title') }} <button @click="active_ipeds = ''" class="map-sidebar__retreat">×</button></h2>
                    <div class="map-sidebar__help">{{ $t('institution.help-text') }}</div>
                    <div class="map-sidebar__institution-data institution-data">
                      <div class="institution-data__institution">
                        <h3 class="institution-data__institution-name">{{active_institution.meta.name}} <a v-if="active_institution.meta.url" target="_blank" class="institution-data__institution-link" :href="preprocess_univ_url(active_institution.meta.url)"></a></h3>
                        <h4 class="institution-data__institution-county">{{active_institution.meta.countyname}}</h4>
                      </div>
                      <div class="institution-data__data-table institution-data__data-table--institution">
                        <div class="institution-data__half-block">
                          <rsi-block :institution="active_institution"></rsi-block>
                          <overview-block :institution="active_institution"></overview-block>
                        </div>
                        <div class="institution-data__half-block">
                          <student-body-block :institution="active_institution"></student-body-block>
                        </div>
                        <div class="institution-data__full-block">
                          <financial-block :institution="active_institution"></financial-block>
                        </div>
                      </div>
                    </div>
                    <a class="button button--download-zip" role="button" target="_blank" href="#" @click.prevent="downloadZip()">{{ $t('download-zip-univ') }}</a>
                    <a class="data-documentation" target="_blank" :href="config.data_documentation_link">{{ $t('view-data-documentation') }}</a>

                  </div>
                  <div v-if="active_state==='compare'" class="map-sidebar__compare">
                    <h2 class="map-sidebar__title">{{ $t('compare.title') }} <button @click="active_compare_ipeds = ''" class="map-sidebar__retreat">×</button></h2>
                    <div class="map-sidebar__help">{{ $t('compare.help-text') }}</div>
                    <div class="map-sidebar__institution-data institution-data">

                      <div class="institution-data__data-table institution-data__data-table--compare">
                        <div class="institution-data__mini-block institution-data__mini-block--institution institution-data__institution">
                          <h3 class="institution-data__institution-name">{{active_institution.meta.name}} <a v-if="active_institution.meta.url" target="_blank" class="institution-data__institution-link" :href="active_institution.meta.url"></a></h3>
                          <h4 class="institution-data__institution-county">{{active_institution.meta.countyname}}</h4>
                        </div>
                        <rsiBlock class="institution-data__mini-block--institution" :institution="active_institution"></rsiBlock>
                        <overviewBlock class="institution-data__mini-block--institution" :institution="active_institution"></overviewBlock>
                        <student-body-block class="institution-data__mini-block--institution" :institution="active_institution"></student-body-block>
                        <financial-block class="institution-data__mini-block--institution" :institution="active_institution"></financial-block>
                        <div class="institution-data__line"></div>
                        <div class="institution-data__mini-block institution-data__mini-block--compare-institution institution-data__mini-block--institution institution-data__institution">
                          <h3 class="institution-data__institution-name">{{active_compare_institution.meta.name}} <a v-if="active_compare_institution.meta.url" target="_blank" class="institution-data__institution-link" :href="active_compare_institution.meta.url"></a></h3>
                          <h4 class="institution-data__institution-county">{{active_compare_institution.meta.countyname}}</h4>
                        </div>
                        <rsiBlock class="institution-data__mini-block--compare-institution" :institution="active_compare_institution"></rsiBlock>
                        <overviewBlock class="institution-data__mini-block--compare-institution" :institution="active_compare_institution"></overviewBlock>
                        <student-body-block class="institution-data__mini-block--compare-institution" :institution="active_compare_institution"></student-body-block>
                        <financial-block class="institution-data__mini-block--compare-institution" :institution="active_compare_institution"></financial-block>
                        <div class="institution-data__full-block"></div>
                      </div>
                    </div>
                    <a class="button button--download-zip" role="button" target="_blank" href="#" @click.prevent="downloadZip()">{{ $t('download-zip-univ-multiple') }}</a>
                    <a class="data-documentation" target="_blank" :href="config.data_documentation_link">{{ $t('view-data-documentation') }}</a>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="rural-univ-map__zoom">-->
    <!--      <button title="zoom map out" v-on:click="leaflet_map.zoomOut()" class="zoom-button rural-univ-map__zoom&#45;&#45;out"><span-->
    <!--          class="zoom-button__focus-control">–</span></button>-->
    <!--      <button title="zoom map in" v-on:click="leaflet_map.zoomIn()" class="zoom-button rural-univ-map__zoom&#45;&#45;in"><span-->
    <!--          class="zoom-button__focus-control">+</span></button>-->
    <!--    </div>-->
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import config from '../config.json'
import {getCookie, setCookie, formatStat, createCSV} from "../utilities";
import value_labels from '../data/value_labels.json'
import institution_data_small from '../data/institution_data_small.json'
import institution_data from '../data/institution_data.json'
import adjacent_counties from '../data/adjacent_counties.json'
import data_ranges from '../data/institution_data_ranges.json'
import state_bounding_boxes from '../data/state_bounding_boxes.json'
import fipsToState from '../data/fipsToState.json'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

import vSelect from 'vue-select'
import 'vue-select/src/scss/vue-select.scss';
import LocaleChanger from "./LocaleChanger";
import rsiBlock from "./rsiBlock";
import overviewBlock from "./overviewBlock";
import studentBodyBlock from "./studentBodyBlock";
import financialBlock from "./financialBlock";
import helpPages from "./helpPages";
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import JSZip from 'jszip'
import { saveAs } from 'file-saver';

import L from 'leaflet';
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LIcon,
  LGeoJson,
} from 'vue2-leaflet';

// const topojson = require('topojson');
// const d3 = require('d3');

export default {
  name: 'Map',
  data: () => {
    return {
      show_help_modal: true,
      disable_help_modal: false,
      modal_first_time: true,
      leaflet_map: undefined,
      // text_labels: [],
      legend_items: [],
      active_state: 'filters',
      institution_data: {},
      current_institution_data: 'normal',
      current_adjacent_county_geojson: {},
      current_compare_adjacent_county_geojson: {},
      value_labels: {},
      adjacent_counties: {},
      state_bounding_boxes: state_bounding_boxes,
      carnegie_options: [],
      state_options: [],
      county_traits_options: [],
      institutional_designation_options: [],
      degrees_awarded_options: [],
      highest_degree_awarded_options: [],
      current_open_drawer: "",
      config: {},
      data_ranges: {},
      open_ipeds_context_menu: null,
      open_ipeds_inst_name_hover: null,
      filters: {
        rsi: [],
        carnegie: [],
        state: [],
        sector: [],
        enrollment_size: [],
        percent_pell: [],
        institutional_designation: [],
        highest_degree_awarded: null,
        degrees_awarded: [],
        county_traits: [],
        text_search: "",
      },
      leaflet_settings: {
        zoomControl: false,
        zoomSnap: 0.1,
        tileLayer: {
          url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
          subdomains: 'abcd',
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>.',
          maxZoom: 19,
        },
        icon_size: {
          default: [28, 28],
          active: [56, 56],
        }
      },
      filtered_results: [],
      active_ipeds: '',
      active_compare_ipeds: '',
      active_institution: {},
      active_compare_institution: {},
      institution_template: config.institution_template
    }
  },
  components: {
    LocaleChanger,
    rsiBlock,
    overviewBlock,
    studentBodyBlock,
    financialBlock,
    VueSlider,
    vSelect,
    LMap,
    LTileLayer,
    LPopup,
    LTooltip,
    LMarker,
    LIcon,
    LGeoJson,
    Vue2LeafletMarkerCluster,
    helpPages,
  },
  created() {
    // this.text_labels = config.text_labels;
    this.legend_items = config.legend_items;
    this.config = config;
    this.data_ranges = data_ranges;
    this.setInstitutionData();
    this.value_labels = value_labels;
    this.adjacent_counties = adjacent_counties;
    if (getCookie('show-help-modal') === '0') {
      this.show_help_modal = false;
      this.disable_help_modal = true;
      this.modal_first_time = false;
    }
    this.carnegie_options = Object.keys(this.value_labels.carnegie).map(key => {
      return {"label": this.value_labels.carnegie[key], "code": key}
    })
    this.state_options = Object.keys(fipsToState).map(key => {
      return {"label": fipsToState[key], "fipscode": key}
    }).sort((a, b) => parseInt(a.fipscode) > parseInt(b.fipscode) ? 1 : -1);

    this.institutional_designation_options = Object.keys(this.value_labels.institutional_designations).map(key => {
      return {"label": this.value_labels.institutional_designations[key], "code": key}
    })
    this.county_traits_options = Object.keys(this.value_labels.county_traits).map(key => {
      return {"label": this.value_labels.county_traits[key], "code": key}
    })
    this.degrees_awarded_options = Object.keys(this.value_labels.degrees_awarded).map(key => {
      return {"label": this.value_labels.degrees_awarded[key], "key": key}
    })
    this.highest_degree_awarded_options = Object.keys(this.value_labels.highest_degree_awarded).map(key => {
      return {"label": this.value_labels.highest_degree_awarded[key], "key": key}
    })

    this.active_institution = this.institution_template;
    this.active_compare_institution = this.institution_template;
    this.setFilterValues(config.default_filter_values)
    this.filterResults()
  },
  methods: {
    close_help_modal() {
      this.show_help_modal = false;
    },
    downloadCSV: function(dataset_type) {
      let dataset = this.institution_data.institutions;
      if (dataset_type === 'filtered') {
        dataset = this.filtered_results;
      }

      let downloadLink = document.createElement("a");
      downloadLink.href = encodeURI(createCSV(dataset));

      let blob = new Blob([createCSV(dataset, false)], { type: 'text/csv;charset=utf-8;' });
      let dl_date = new Date();
      let filename = `rsi-data-export__${dataset_type}__${dl_date.toISOString().replace(/[^\dTZ_-]+/g, '_')}.csv`;

      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        let link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    downloadZip: function() {

      let zip = new JSZip();

      let ipeds_ids = [this.active_ipeds]
      if (this.active_compare_ipeds) {
        ipeds_ids.push(this.active_compare_ipeds)
      }

      ipeds_ids.forEach((ipeds_id) => {
        let ipeds_folder = zip.folder(`ipeds_${ipeds_id}`);
        ipeds_folder.file(`${ipeds_id}_data.csv`, createCSV([this.institution_data.institutions.find(i => i.ipeds === ipeds_id)], false))
        ipeds_folder.file(`${ipeds_id}_data.json`, JSON.stringify(this.institution_data.institutions.find(i => i.ipeds === ipeds_id)))
        let svgs = document.getElementsByClassName('institution-data__graph');
        svgs.forEach((svg) => {
          if (svg.dataset.ipeds !== ipeds_id) {
            return;
          }
          ipeds_folder.file(svg.dataset.filename, svg.innerHTML)
        })

      })

      zip.generateAsync({type:"blob"}).then(function(content) {
        let dl_date = new Date();
        saveAs(content, `rsi-univ-download__${ipeds_ids.join('-')}__${dl_date.toISOString().replace(/[^\dTZ_-]+/g, '_')}.zip`);
      });

    },
    unfocusContextMenu: function() {
      this.open_ipeds_context_menu = null
    },
    setInstitutionData: function() {
      if (this.current_institution_data === 'normal') {
        this.institution_data = institution_data
      }
      else {
        this.institution_data = institution_data_small
      }
    },
    activateInstitution: function(ipeds, active_type) {
      if (typeof active_type !== "undefined" && active_type) {
        this.open_ipeds_context_menu = null;
        if (active_type === 'active') {
          this.active_ipeds = ipeds
          this.$refs.rural_univ_map.mapObject.closePopup();
          return;
        }
        else if (active_type === 'compare') {
          this.active_compare_ipeds = ipeds
          this.$refs.rural_univ_map.mapObject.closePopup();
          return;
        }
      }

      // if we're already looking at this institution, don't open the popup and skip
      if (this.active_ipeds == ipeds || this.active_compare_ipeds == ipeds) {
        this.$nextTick(() => {
          this.$refs.rural_univ_map.mapObject.closePopup();
        })
        return;
      }

      if (this.active_ipeds.length > 0) {
        // not the first item, we're letting it open the context menu popup
      }
      else {
        // don't open the contextual menu if this is the first one we're clicking
        this.$nextTick(() => {
          this.$refs.rural_univ_map.mapObject.closePopup();
        })
        this.active_ipeds = ipeds
      }
    },
    clusterIconCreateFunction: function(cluster) {
      const childCount = cluster.getChildCount();
      return new L.DivIcon({ html: '<div><span>' + childCount + '</span></div>', className: 'marker-cluster marker-cluster-medium', iconSize: new L.Point(40, 40) });
    },
    latlng: L.latLng,
    filterResults: function () {
      // console.log('filtering results')
      let that = this;
      this.filtered_results = this.institution_data.institutions.filter(institution => {
        if (that.filters.sector.indexOf(institution.overview.sector) === -1) {
          // console.log('failed sector')
          return false
        }
        if (that.filters.carnegie.length > 0 && !that.filters.carnegie.some(carn_filter => carn_filter.split('|').indexOf(institution.overview.carnegie) !== -1)) {
          // console.log('failed carnegie')
          return false
        }
        if (!(that.filters.rsi[0] <= institution.rsi.rsi_score && institution.rsi.rsi_score <= that.filters.rsi[1])) {
          // console.log('failed rsi', institution.rsi.rsi_score, that.filters.rsi[0], that.filters.rsi[1], that.filters.rsi[0] <= institution.rsi.rsi_score, institution.rsi.rsi_score <= that.filters.rsi[1])
          return false
        }
        if (that.filters.state.length > 0 && that.filters.state.indexOf(institution.meta.fips.substring(0, 2)) === -1) {
          // console.log('failed state')
          return false
        }
        if (!(that.logEnrollmentSizeOptions[that.filters.enrollment_size[0]] <= institution.student_body.enrolled_total
            && institution.student_body.enrolled_total <= that.logEnrollmentSizeOptions[that.filters.enrollment_size[1]])) {
          // console.log('failed enrollment', institution.student_body.enrolled_total)
          return false
        }
        if (institution.student_body.percent_pell !== -1 && !(that.filters.percent_pell[0] <= institution.student_body.percent_pell && institution.student_body.percent_pell <= that.filters.percent_pell[1])) {
          // console.log('failed pell')
          return false
        }
        if (that.filters.institutional_designation.length > 0 && !that.filters.institutional_designation.every((designation) => institution.overview[designation] == "1")) {
          // console.log('failed institutional_designation')
          return false
        }
        if (that.filters.county_traits.length > 0 && !that.filters.county_traits.every((trait) => institution.overview[trait] == "1")) {
          // console.log('failed county_traits')
          return false
        }
        if (that.filters.degrees_awarded.length > 0 && !that.filters.degrees_awarded.every((degree_type) => institution.overview[degree_type] == "1")) {
          // console.log('failed county_traits')
          return false
        }
        if (that.filters.highest_degree_awarded && that.filters.highest_degree_awarded.length > 0 && that.filters.highest_degree_awarded !== institution.overview.highest_degree_awarded) {
          // console.log('failed county_traits')
          return false
        }

        if (that.filters.text_search.length > 0 && !institution.meta.name.toLowerCase().includes(that.filters.text_search.toLowerCase())) {
          // console.log('failed text search')
          return false
        }
        else {
          // console.log('pass text search')
        }
        // console.log('succeeded')
        return true;
      })
    },
    log: function () {
      console.log(...arguments)
    },
    hide_help_modal: function () {
      this.show_help_modal = false;
    },
    setFilterValues: function (filter_values) {
      Object.keys(filter_values).forEach(key => {
        this.filters[key] = filter_values[key]
      })
    },
    resetFilters: function() {
      this.setFilterValues(this.config.default_filter_values);
      this.resetView();
      this.active_compare_ipeds = "";
      this.active_ipeds = "";
    },
    resetView: function() {
      this.setView()
    },
    setView: function (bounds) {
      bounds = bounds || L.latLngBounds(L.latLng(24, -128), L.latLng(51, -64))
      this.$nextTick(() => {
        this.$refs.rural_univ_map.mapObject.fitBounds(bounds, {
          paddingTopLeft: [0, 0],
          paddingBottomRight: [530 + 32, 0]
        });
      });
    },

    percentFormatter: function (num) {
      return `${num}%`;
    },
    formatStat: formatStat,
    getActualEnrollmentSize: function (size) {
      const get_mapped_size = (size) => {
        return this.logEnrollmentSizeOptions[parseInt(size)]
      }

      if (typeof (size) === 'number') {
        return get_mapped_size(size)
      }
      if (typeof (size) === 'undefined') {
        return 0
      }
      return [get_mapped_size(size[0]), get_mapped_size(size[1])]
    },
    setAdjacentCountyGeoJson: function(main_fips, set_var) {
      this.$http.all(
          this.adjacent_counties[main_fips].adjacent_counties
              .map(fips => this.$http.get(`/data/counties/${fips}.json`))
      ).then((results) => {
        this[set_var] = {"type": "FeatureCollection", "features": results.map(i => i.data)};
      })
    },
    styleGeoJson: function(geojson) {
      return {
        color: config.colors.grey_lines,
        weight: 1,
        opacity: 0.5,
        fillColor: config.colors.teal,
        fillOpacity: (geojson.id === this.active_institution.meta.fips) ? 0.3 : 0.15
      }
    },
    styleGeoJsonCompare: function(geojson) {
      return {
        color: config.colors.grey_lines,
        weight: 1,
        opacity: 0.5,
        fillColor: config.colors.green,
        fillOpacity: (geojson.id === this.active_compare_institution.meta.fips) ? 0.6 : 0.3
      }
    },
    preprocess_univ_url: function(url) {
      // http url to avoid potential broken links.
      return !/^https?:\/\//i.test(url) ? `http://${url}` : url;
    },
  },
  mounted() {
    this.resetView();
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterResults()
      }
    },
    'filters.state': {
      handler(d) {
        this.setView(d.map(s_fips => this.state_bounding_boxes[s_fips]).reduce((acc, curr) => {
          if (typeof acc === 'undefined') {
            return L.latLngBounds(L.latLngBounds(L.latLng(...curr[0]), L.latLng(...curr[1])))
          }
          return acc.extend(L.latLngBounds(L.latLng(...curr[0]), L.latLng(...curr[1])))
        }, undefined))
      }
    },
    disable_help_modal: function() {
      setCookie('show-help-modal', this.disable_help_modal ? '0' : '1', 365)
    },
    current_institution_data: function() {
      this.setInstitutionData();
      this.filterResults();
    },
    active_ipeds: function() {
      if (this.active_compare_ipeds.length > 0) {
        this.active_state = "compare"
      }
      else {
        if (this.active_ipeds.length > 0) {
          this.active_state = "institution"
        }
        else {
          this.active_state = "filters"
        }
      }

      if (this.active_ipeds) {
        this.active_institution = this.institution_data.institutions.find(i => i.ipeds === this.active_ipeds)
        this.current_adjacent_county_geojson = {};
        this.setAdjacentCountyGeoJson(this.active_institution.meta.fips, 'current_adjacent_county_geojson');
      }
      else {
        this.active_institution = this.institution_template;
        this.current_adjacent_county_geojson = {}
      }
    },
    active_compare_ipeds: function() {
      if (this.active_compare_ipeds.length > 0) {
        this.active_state = "compare"
      }
      else {
        if (this.active_ipeds.length > 0) {
          this.active_state = "institution"
        }
        else {
          this.active_state = "filters"
        }
      }

      if (this.active_compare_ipeds) {
        this.active_compare_institution = this.institution_data.institutions.find(i => i.ipeds === this.active_compare_ipeds)
        this.current_compare_adjacent_county_geojson = {};
        this.setAdjacentCountyGeoJson(this.active_compare_institution.meta.fips, 'current_compare_adjacent_county_geojson');
      }
      else {
        this.active_compare_institution = this.institution_template;
        this.current_compare_adjacent_county_geojson = {}
      }
    }
  },
  computed: {
    total_institution_count() {
      let counts = {
        'total': this.institution_data.institutions.length,
        '5': this.institution_data.institutions.filter(i => i.overview.sector == "5").length,
        '4': this.institution_data.institutions.filter(i => i.overview.sector == "4").length,
        '2': this.institution_data.institutions.filter(i => i.overview.sector == "2").length,
        '1': this.institution_data.institutions.filter(i => i.overview.sector == "1").length,
      }
      return counts
    },
    cssImageVars() {
      return {
        '--icon-size-active': this.leaflet_settings.icon_size.active[1] + "px",
        '--icon-size-default': this.leaflet_settings.icon_size.default[1] + "px",
      }
    },
    logEnrollmentSizeOptions() {
      return [0, ...new Set(Array.from(new Array(this.config.ranges.enrollment_size.step_count + 1), (_, i) => Math.round(Math.pow(Math.pow(this.config.ranges.enrollment_size.max, 1 / this.config.ranges.enrollment_size.step_count), i))))]
    },
    logEnrollmentSizeMarks() {
      let marks = {}
      marks[2] = {label: this.config.ranges.enrollment_size.min}
      marks[this.logEnrollmentSizeOptions.length - 1] = {label: this.config.ranges.enrollment_size.max}
      return marks
    }
    // logEndowmentSizeOptions() {
    //   return [...new Set(Array.from(new Array(this.config.ranges.endowment_size.step_count + 1), (_, i) => Math.round(Math.pow(Math.pow(this.config.ranges.endowment_size.max, 1/this.config.ranges.endowment_size.step_count), i ))))]
    // }
  }
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@mixin transition($transition...) {
  transition: $transition;
}

@mixin transition-property($property...) {
  transition-property: $property;
}

@mixin transition-duration($duration...) {
  transition-duration: $duration;
}

@mixin transition-timing-function($timing...) {
  transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
  transition-delay: $delay;
}

@mixin nt($prop: all, $time: 0.25s, $anim: ease-in-out) {
  //
  // These are seperated to allow for multiple element selection with unquote('item, item2') syntax.
  // @include nt($el: unquote('width, opacity'));
  // You may also use single selectors.
  // @include nt($el: width);
  // Or none at all
  // @include nt;
  //
  @include transition-property($prop);
  @include transition-duration($time);
  @include transition-timing-function($anim);
}

@import './css_vars.scss';


@font-face {
  font-family: "Gotham";
  font-weight: 900;
  src: local("Gotham"), url(../assets/fonts/Gotham/GothamHTF-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url(../assets/fonts/Gotham/GothamHTF-Book.otf) format("opentype");
}


.rural-univ-map {
  font-family: "Gotham", "Montserrat", sans-serif;
  font-size: 14px;
  height: 80vh;
  min-height: 700px;
  max-height: 100vh;
  width: 100%;
  position: relative;
  color: $text-color;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .vue-slider {
    box-sizing: content-box;
  }

  h2, h3, h4, h5, h6, a, div, span, button {
    font-family: "Gotham", "Montserrat", sans-serif;
  }
}


.rural-univ-map__map {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.rural-univ-map__help-modal {
  position: absolute;
  height: 100%;
  width: 100%;
  //margin: $sidebar-ext-padding $sidebar-ext-padding $sidebar-ext-padding 0;
  z-index: 4;
}


.button {
  background: $gradient;
  border: 0;
  border-radius: $border-radius / 2;
  padding: 6px 16px 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.help-modal__start-button {
  margin: 20px 0 30px;
}
.button--download-csv-dataset {
  margin: 20px auto 10px;
  display: inline-block;
  align-self: center;
  text-transform: uppercase;
  + .button--download-csv-dataset {
    margin-top: 0px;
  }
}

.button--download-zip {
  margin: 20px auto 10px;
  display: inline-block;
  align-self: center;
  text-transform: uppercase;
  text-align: center;
  + .button--download-zip {
    margin-top: 0px;
  }
}

.rural-univ-map__sidebar-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  max-height: 100%;
  max-height: calc(100% - #{$sidebar-ext-padding * 2});
  width: $sidebar-size + $sidebar-ext-padding;
  margin: $sidebar-ext-padding $sidebar-ext-padding $sidebar-ext-padding 0;
  z-index: 2;
}

.rural-univ-map__sidebar-border-outer-radius {
  height: 100%;
  width: 100%;
  overflow: hidden;

  border-radius: $border-radius + $sidebar-border-thickness;
  //box-shadow: $default-box-shadow;
  z-index: 3;
  position: relative;
  background: $gradient;
}

.rural-univ-map__sidebar-border-inner-radius {
  height: 100%;
  width: 100%;
  padding: $sidebar-border-thickness;
  overflow: hidden;

  box-shadow: $default-box-shadow;
  position: relative;
}

.rural-univ-map__sidebar-border-inner-radius-background {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: $border-radius;
}

.rural-univ-map__sidebar {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
}

.rural-univ-map__drawers {
  position: absolute;
  right: 100%;
  z-index: 2;
  color: white;
  margin-top: 20px;
}

.rural-univ-map__drawer-title {
  padding: 14px 30px 14px 20px;
  font-size: 24px;
  font-weight: bold;
  margin: 0;

  button {
    position: absolute;
    right: 10px - 8px;
    top: 14px - 8px;
    background: 0;
    border: 0;
    cursor: pointer;
    color: white;
    font-weight: bold;
    padding: 8px;
  }
}

.rural-univ-map__drawer-explanation {
  font-weight: bold;
  margin-bottom: 8px;
}

$drawer-tab-size: 50px;
$drawer-tab-padding: 12px;
$drawer-tab-icon-size: $drawer-tab-size - ($drawer-tab-padding * 2);

.rural-univ-map__drawer-tab {
  width: $drawer-tab-size;
  height: $drawer-tab-size;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  background-color: white;
  border: 0;
  padding: 0;
  margin-bottom: 10px;
}

.preset {
  display: block;
  background: none;
  color: white;
  border: 0;
  font-weight: normal;
  padding: 6px 10px;
  margin: 0 -10px;
  cursor: pointer;
}

.rural-univ-map__drawer {
  transform: translateX(0);
  transition: transform 0.5s ease;
}

.rural-univ-map__drawer--active {
  transform: translateX(-1 * $drawer-width);
}

.rural-univ-map__drawer-tab {
  opacity: 1;
  transition: opacity 0.1s ease;
}

.rural-univ-map__drawer--other-active {
  .rural-univ-map__drawer-tab {
    opacity: 0;
    pointer-events: none;
  }
}

.rural-univ-map__drawer-tab-icon {
  width: $drawer-tab-icon-size;
  height: $drawer-tab-icon-size;
  display: block;
  margin: $drawer-tab-padding;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.rural-univ-map__drawer {
  position: relative;
  text-align: left;
}

.rural-univ-map__drawer-content {
  padding: 6px 20px 20px;

  max-height: 500px;
}

.results-list {
  overflow: hidden;
}

.results-list__list {
  overflow-y: scroll;
  max-height: 400px;
  margin: 0 -8px;
}

.results-list__search {
}

.results-list__search-field {
  margin-bottom: 8px;
  padding: 8px;
  box-sizing: border-box;
  max-width: 100%;
}

.results-list__help {
  font-weight: bold;
  margin: 8px 0;
}

.results-list__item {
  border: 0;
  background: 0;
  color: white;
  font-weight: normal;
  text-align: left;
  padding: 8px 8px;
  cursor: pointer;
  display: block;

  &.results-list__item--active {
    font-weight: bold;
  }
  &.results-list__item--active-compare {
    font-weight: bold;
  }
}

.results-list__scrollable {
  height: 100%;
  //overflow-y: scroll;
}

.rural-univ-map__drawer-contents {
  position: absolute;
  left: 100%;
  top: 0;
  width: $drawer-width;
}

.rural-univ-map__drawer--presets {
  .rural-univ-map__drawer-contents,
  .rural-univ-map__drawer-tab {
    background: $gradient;
  }

  .rural-univ-map__drawer-tab-icon {
    background-image: url('../assets/icons/preset-filters-icon.svg');
  }
}

.rural-univ-map__drawer--help {
  .rural-univ-map__drawer-contents,
  .rural-univ-map__drawer-tab {
    background: $blue;
  }

  .rural-univ-map__drawer-tab-icon {
    background-image: url('../assets/icons/help-icon.svg');
  }
}

.rural-univ-map__drawer--list {
  .rural-univ-map__drawer-contents,
  .rural-univ-map__drawer-tab {
    background: $teal;
  }

  .rural-univ-map__drawer-tab-icon {
    background-image: url('../assets/icons/list-view-icon.svg');
  }
}

.rural-univ-map__drawer--reset {
  .rural-univ-map__drawer-tab {
    background: $green;
  }

  .rural-univ-map__drawer-tab-icon {
    background-image: url('../assets/icons/reset-filters-icon.svg');
  }
}

.filter {
  label {
    text-align: right;
    font-weight: bold;
    align-self: center;
  }
  table {
    border-collapse: collapse;
    td {
      padding: 2px 2px;
      &:not(:first-child) {
        padding-left: 8px;
      }
    }
  }
}


.legend-item {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
}

.legend-item__label {
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  align-items: center;
}

.locale-changer {
  position: absolute;
  top: $sidebar-int-padding + 5px;
  right: $sidebar-int-padding;
}

.filter--sector {
  tr:last-child {
    td {
      padding-top: 5px;
    }
  }
}

.filter__table-checkbox {
  border-right: 1px solid black;
}

.filter__slider {
  //padding: 0 7px;
  width: 100%;
}

.filter--sector-checkboxes {
  display: flex;
  flex-flow: row wrap
}

.filter {
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  label {
    flex: 100px 0 0;
    margin-right: 10px;
  }

  > div {
    flex: 200px 1 1;
  }
}

.advanced-filters {
  summary {
    padding: 12px 0;
    font-weight: bold;
    color: $teal;
    position: relative;
    list-style: none;
    &::-webkit-details-marker {
      display: none;
    }
  }
  summary::before {
    content:"▲";
    //height: 20px;
    color: $teal;
    transform-origin: center center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    transition: transform 0.25s ease;
    /* you can style it however you want, use background-image for example */
  }

  /* By using [open] we can define different styles when the disclosure widget is open */
  &[open] summary::before {
    transform: rotate(-179.9deg) translate(0, 30%);
  }
}

.filter--rsi,
.filter--percent-pell,
.filter--enrollment-size {
  margin-top: 50px;
  margin-bottom: 30px;
}

.rural-univ-map__legend {
  position: absolute;
  left: 0;
  bottom: 0;
  //width: 500px;
  margin: 0 0;
  padding: 16px;
  z-index: 2;
  display: flex;
  flex-flow: row nowrap;
  border-top-right-radius: $border-radius;

  background: white;
  box-shadow: $legend-box-shadow;
}

.legend-item {
  display: flex;
  font-size: 14px;
  align-items: center;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}
//$icon-size: 26px;

.legend-item__swatch {
  width: calc(var(--icon-size-default) - 2px);
  height: calc(var(--icon-size-default) - 2px);
  font-weight: bold;
  border: 0;
  border-radius: 50%;
  text-align: center;
  line-height: calc(var(--icon-size-default) - 2px);
  font-size: calc(var(--icon-size-default) - 12px);
  margin-right: 8px;
  margin-left: 6px;
  color: white;
  flex-shrink: 0;
}

.institution-icon {
  //width: var(--icon-size-default) !important;
  //height: var(--icon-size-default) !important;
  font-weight: bold;
  border: 0;
  border-radius: 50%;
  text-align: center;
  line-height: var(--icon-size-default);
  font-size: calc(var(--icon-size-default) - 10px);
  color: white;
  background-color: black;
  &.institution-icon--private {
    background: $green;
  }
  &.institution-icon--public {
    background: $teal;
  }
  &.institution-icon--active-institution {
    background: $gradient;
    z-index: 99999 !important;
    //width: var(--icon-size-active);
    //height: var(--icon-size-active);
    line-height: var(--icon-size-active);
    font-size: calc(var(--icon-size-active) - 20px);
  }
  &.institution-icon--active-compare-institution {
    background: $gradient;
    z-index: 99999 !important;
    //width: var(--icon-size-active);
    //height: var(--icon-size-active);
    line-height: var(--icon-size-active);
    font-size: calc(var(--icon-size-active) - 20px);
  }
}

.institution-icon__institution-name {
  //background-color: white;
  //border: 2px solid $teal;
  //border-radius: $border-radius;
  //max-width: 300px;
  //white-space: nowrap;
  //padding: 4px 8px;
  //color: black;
  //font-weight: normal;
  //transform: translateX(-50%) translateY(5px);
  //left: 50%;
  //position: absolute;
  //z-index: 100000;
  //font-size: 16px;
  //line-height: 1.2;
}

.institution-icon__context-menu {
  //background-color: white;
  //border: 2px solid $green;
  //border-radius: $border-radius;

  //transform: translateX(-50%) translateY(5px);
  //left: 50%;
  //position: absolute;
  //z-index: 100001;


  color: black;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.2;

  .leaflet-popup-content-wrapper {
    padding: 0;
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;
  }
  .leaflet-popup-content {
    margin: 0;
  }

  .institution-icon__context-menu-button-wrapper {
    display: flex;
    flex-flow: row nowrap;
  }
  button {
    flex: 0 0 50%;
    background: 0;
    cursor: pointer;
    border: 0;
    padding: 10px 6px;
    &:last-child {
      border-left: 1px solid #ccc;
    }
    transition: background 0.15s ease;
    &:hover, &:focus {
      background: rgba(0,0,0,0.10);
    }
  }
}

.leaflet-tooltip {
  border-radius: $border-radius;
  overflow: hidden;
}

.institution-data__institution-name {
  margin: 0.5em 0 0.2em;
  font-weight: bold;
  color: $teal;
}
.institution-data__institution-link {
  background: url('../assets/icons/link.svg') center center;
  background-size: 65%;
  background-repeat: no-repeat;
  width: 28px;
  height: 1.4em;
  display: inline-block;
  transform: translateY(6px);
  padding: 4px;
}
.institution-data__institution-county {
  margin: 0;
  font-weight: normal;
}

.institution-data__mini-title {
  color: $teal;
  font-size: 14px;
  margin: 1em 0 1em;
}

.institution-data__institution {
  margin: 8px 0 16px;
}

.institution-data__mini-block--rsi {
  .institution-data__mini-stat-value {
    font-weight: bold;
  }
}

.institution-data__mini-block--overview {
  .institution-data__mini-stat-label {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }
}
.institution-data__mini-stat {
  margin: 10px 0 10px 5px;
}

.institution-data__data-table {
  display: flex;
  flex-flow: row wrap;
}

.institution-data__data-table--compare {
  display: grid;

  padding-top: 16px;
  grid-template-rows: auto auto auto auto auto;
  grid-template-columns: 1fr 1px 1fr;
  grid-template-areas: "i_name line c_name"
                        "i_rsi line c_rsi"
                        "i_overview line c_overview"
                        "i_student_body line c_student_body"
                        "i_financial line c_financial";

  .institution-data__line {
    grid-area: line;
    background: black;
  }
  .institution-data__mini-block--institution {
    padding-right: 8px;
    &.institution-data__institution {
      grid-area: i_name;
    }
    &.institution-data__mini-block--rsi {
      grid-area: i_rsi;
    }
    &.institution-data__mini-block--overview {
      grid-area: i_overview;
    }
    &.institution-data__mini-block--student-body {
      grid-area: i_student_body;
    }
    &.institution-data__mini-block--financial {
      grid-area: i_financial;
    }
  }
  .institution-data__mini-block--compare-institution {
    padding-left: 8px;
    &.institution-data__institution {
      grid-area: c_name;
    }
    &.institution-data__mini-block--rsi {
      grid-area: c_rsi;
    }
    &.institution-data__mini-block--overview {
      grid-area: c_overview;
    }
    &.institution-data__mini-block--student-body {
      grid-area: c_student_body;
    }
    &.institution-data__mini-block--financial {
      grid-area: c_financial;
    }
  }
}

.institution-data__data-table--institution {
  .institution-data__half-block:first-child {
    border-right: 1px solid black;
    padding-right: 8px;
    padding-bottom: 8px;
    + .institution-data__half-block {
      padding-left: 8px;
      padding-bottom: 8px;
    }
  }
  .institution-data__full-block {
    border-top: 1px solid black;

    .institution-data__mini-stats {
      &:first-child {
        padding-right: 8px;
      }
      + .institution-data__mini-stats {
        padding-left: 8px;
      }
    }
  }
}

.institution-data__full-block {
  flex-basis: 100%;
}
.institution-data__half-block {
  flex-basis: 50%;
}
.rural-univ-map__zoom {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  margin: 0 0 32px 32px;
  height: 22px;
  background: white;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: $default-box-shadow;
  display: flex;

  button {
    border: 0;
    margin: 0;
    padding: 0 5px;
    font-size: 18px;
    display: block;
    width: 25px;
    //height: 20px;
    line-height: 1;
    background: transparent;
    cursor: pointer;
    height: 100%;

    &:hover {
      background: rgba(0, 0, 0, 0.1)
    }
  }

  button:first-of-type {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.zoom-button {
  position: relative;
}

.zoom-button__focus-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.map-sidebar {
  //background: white;
  //box-shadow: $default-box-shadow;
  //overflow-y: auto;
}

.map-sidebar__scrollable {
  padding: $sidebar-int-padding;
  position: relative;
}

.map-sidebar__title {
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  margin: 0.15rem 0 0.75rem;
  position: relative;
}

.map-sidebar__title--padded {
  padding-top: 2.25rem;
}

.map-sidebar__retreat {
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  background: 0;
  line-height: 1;
  font-size: 18px;
  color: $blue;
  padding: 12px;
  cursor: pointer;
  font-weight: bold;
}

.map-sidebar__filters {
  display: flex;
  flex-flow: column nowrap;
  .map-sidebar__title {
    text-align: center;
    color: $teal;
  }
}

.map-sidebar__institution {
  .map-sidebar__title {
    color: $blue;
  }
}

.map-sidebar__compare {
  .map-sidebar__title {
    color: $blue;
  }
}

.map-sidebar__intro-text {
  font-size: 16px;
  text-align: left;
  font-weight: normal;
  margin: 0.25rem 0 0.75rem;
}

.map-sidebar__view-types {
  margin: 1rem 0 0.5rem;
}

.map-sidebar__intro-button {
  display: block;
  width: 100%;
  border: 1px solid $grey;
  padding: 8px 8px;
  font-size: 14px;
  background: transparent;
  cursor: pointer;
  @include nt();

  &:hover {
    background-color: $map-light;
  }

  &.map-sidebar__intro-button--active {
    background-color: $primary-color;
    color: white;
  }

  + .map-sidebar__intro-button {
    margin-top: 8px;
  }
}

.map-sidebar__district-dropdown-select {
  display: block;
  width: 100%;
  border: 1px solid $grey;
  padding: 8px 8px;
  font-size: 14px;
  background: transparent;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.map-sidebar__district-dropdown {
  position: relative;
  margin-top: 1rem;

  &:after {
    content: '\1405';
    pointer-events: none;
    position: absolute;
    display: block;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    padding: 0 8px;
    font-size: 16px;
  }

  &:focus-within:after {
    transform: translateY(-50%) rotate(-90deg);
  }
}


.content-type-select__icons {
  display: flex;
  align-content: center;

  .content-type {
    border: 1px solid transparent;
    background-color: transparent;
    flex: 50% 0 0;
    padding: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:active {

    }
  }
}

.content-type__icon {
  display: block;
  position: relative;
  height: 50px;
  width: 50px;

  .content-type__icon-image {
    opacity: 1;
    transition: 0.5s ease opacity;
  }
}

.content-type__icon--inactive {
  .content-type__icon-image--active {
    opacity: 0;
  }
}

.content-type:hover {
  .content-type__icon-image--active {
    opacity: 1;
  }
}

.content-type__icon--active {
  .content-type__icon-image--inactive {
    opacity: 0;
  }
}

.content-type__icon-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

$content-type-select-bar-height: 16px;

.content-type-select__bar-container {
  border: 1px solid $grey;
  border-radius: $content-type-select-bar-height / 2;
  height: $content-type-select-bar-height;
  position: relative;
}

.content-type-select__bar {
  width: 50%;
  height: $content-type-select-bar-height - 4;
  margin: 1px;
  display: block;
  border-radius: $content-type-select-bar-height / 2 - 2;
  background-color: $primary-color;
  //position: absolute;
  @include nt();
}

.content-type-select__bar--left {
  transform: translateX(0);
}

.content-type-select__bar--right {
  transform: translateX(calc(100% - 2px));
}

.map-sidebar__accordions {

}

.map-sidebar__accordion {
  border: 1px solid $grey;
  margin: 12px 0;
}


.accordion__title {
  display: flex;
  align-items: center;
  padding: 12px;
}

.accordion__content {
  padding: 0 12px 12px;
}

.accordion__title-text {
  font-weight: bold;
  color: $primary-color;
  font-size: 16px;
}

.accordion__title-icon {
  width: 36px;
  display: block;
  overflow: hidden;
  max-height: 40px;
  flex: 36px 0 0;
  margin: 0 8px 0 -6px;

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.accordion__title-expander {
  font-size: 30px;
  line-height: 30px;
  margin-left: auto;
  font-weight: 100;

  &.accordion__title-expander--opened {
    //font-size:
  }
}

.stat__info {
  padding: 6px;
  margin: -6px 0;
  font-weight: normal;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  transform: translateY(-3px);
  display: inline-block;

  &:hover {
    .stat__tooltip {
      opacity: 1;
      pointer-events: initial;

    }
  }

  .stat__tooltip {
    top: 100%;
    opacity: 0;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 12px;
    padding: 8px;
    border-radius: 3px;
    position: absolute;
    width: 200px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.stat__county_avg,
.county-stat-header {
  border-left: 1px solid $light-grey;

  + .county-stat-header,
  + .stat__county_avg {
    border-left: 0;
  }
}


.map-sidebar__links {
  margin-top: 10px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.map-sidebar__link {
  border: 1px solid transparent;
  background-color: transparent;
  text-decoration: none;
  color: $text-color;
  flex: 50% 0 0;
  padding: 8px;
  display: flex;
  text-align: center;
  align-items: center;
  cursor: pointer;
  flex-basis: 85px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:active {

  }
}

.map-sidebar__link-icon {
  width: 35px;
  height: 35px;
  display: block;
  flex-shrink: 0;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }
}

.map-sidebar__link-text {
  font-size: 14px;
  margin-left: 3px;
}

.map-sidebar__link-icon {
  position: relative;

  .map-sidebar__link-icon__active,
  .map-sidebar__link-icon__default {

    transition: opacity 0.5s ease;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .map-sidebar__link-icon__default {
    opacity: 1;
  }

  .map-sidebar__link-icon__active {
    opacity: 0;
  }
}

.map-sidebar__link:hover {
  .map-sidebar__link-icon__active {
    opacity: 1;
  }

  .map-sidebar__link-icon__default {
    opacity: 0;
  }
}

.map-sidebar__glossary {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: $sidebar-int-padding;
  background-color: white;

  h4 {
    margin-bottom: 0.5rem;
  }
}

.glossary__close {
  background: none;
  cursor: pointer;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  margin: 0;
}

.glossary__term-label {
  font-weight: 600;
}

.glossary__title {
  margin-top: 0;
}


/* map animations */
.fade-enter-to, .fade-leave {
  opacity: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
  transition-property: opacity, height, max-height;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.vert-slide-enter-to, .vert-slide-leave {
  opacity: 1;
  max-height: 1000px;
}

.vert-slide-enter-active, .vert-slide-leave-active {
  transition: opacity 0.5s ease;
  transition-property: opacity, height, max-height;
}

.vert-slide-enter, .vert-slide-leave-to /* .vert-slide-leave-active below version 2.1.8 */
{
  opacity: 0;
  max-height: 0px;
}


#rural-univ-map__map {
  &:before,
  &:after {
    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none;
    position: absolute;
  }

  &:before {
    content: 'Use ctrl + scroll to zoom the map';
    top: 50%;
    left: 30%;
    z-index: 999;
    font-size: 34px;
    transform: translate(-50%, -50%);
  }

  &:after {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: '';
    background: #000000;
    z-index: 999;
  }
}


#rural-univ-map__map.map-scroll:before {
  opacity: 1;
}

#rural-univ-map__map.map-scroll:after {
  opacity: 0.2;
}

.leaflet-pane > svg path.leaflet-interactive {
  transition: fill 0.25s ease;

  &:hover {
    //fill: $primary-color-hover
  }
}

.data-graph-legend {
  //max-width: 100px;
  float: right;
  display: block;
}

.data-graph-legend__item {
  display: flex;
}

.data-graph-legend__bar {
  height: 18px;
  display: inline-block;
  width: 75px;
  flex-shrink: 0;
}

.data-graph-legend__text {
  line-height: 18px;
  display: inline-block;
  font-weight: bold;
  padding: 0px 4px;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// vue-select overrides

.vs__dropdown-option {
  white-space: normal;
  padding: 5px 10px;
}

.data-documentation {
  padding: 5px 0;
  text-align: center;
  display: inline-block;
}

// vue-slider-component overrides

.vue-slider-process {
  //box-shadow: 0 0 0 0px;
  background: $gradient;
}

</style>
