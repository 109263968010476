<template>
  <div class="rural-univ-map__help-modal help-modal">
    <div class="help-modal__modal">
      <div class="help-modal__border-outer-radius">
        <div class="help-modal__border-inner-radius">
          <div class="help-modal__border-inner-radius-background">
            <div class="help-modal__content">
              <locale-changer></locale-changer>
              <h2 class="help-modal__title" :class="{'help-modal__title--padded': $i18n.locale === 'es'}">{{ $t('help.title') }}</h2>
              <button class="help-modal__close" v-if="allow_closing" @click="close_help_modal">×</button>
              <div class="help-modal__list">
                <button v-for="help_topic in help_topics" :key="help_topic.key"
                        :class="{'help-topic': true, 'help-topic--active': active_help_topic_key === help_topic.key}"
                        @click="active_help_topic_key = help_topic.key">{{ $t(`help_topics.${help_topic.key}.title`) }}
                </button>
              </div>
              <div class="help-modal__body">
                <div class="help-modal__body-title" v-html="$t(`help_topics.${active_help_topic.key}.title`)"></div>
                <div class="help-modal__body-description" v-html="$t(`help_topics.${active_help_topic.key}.description`)"></div>
                <div class="help-modal__body-video" v-if="active_help_topic.video">
                  <iframe width="560" height="315"
                          :src="'https://www.youtube.com/embed/' + active_help_topic.video + '?autoplay=1'"
                          title="YouTube video player" frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen></iframe>
                </div>
                <div class="help-modal__nav">
                  <button v-html="$t('help.previous')" @click="prev_help_topic"
                          :disabled="!prev_help_topic_available()"></button>
                  <button v-html="$t('help.next')" @click="next_help_topic"
                          :disabled="!next_help_topic_available()"></button>
                </div>
              </div>
              <div class="help-modal__close-dontshow">
                <div class="help-modal__close-help-modal">
                  <button class="help-modal__close-help-modal-button" :disabled="!allow_closing" @click="close_help_modal">{{ $t('help.close-help-modal') }}</button>
                  <span v-if="!allow_closing" class="help-modal__close-help-modal-help-text">{{ $t('help.close-allowed-in', {'close_timeout': close_timeout_left}) }}</span>
                  <span v-else class="help-modal__close-help-modal-help-text">{{ $t('help.close-help-modal-help') }}</span>
                </div>
                <div class="help-modal__dont-show-again">
                  <input id="dont-show-again" type="checkbox" v-bind:value="disable_help_modal" v-on:input="update_disable_value($event.target.checked)">
                  <label for="dont-show-again">{{ $t('help.dont-show-again') }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="help-modal__background"></div>
  </div>
</template>

<script>
import config from '../config.json'
import help from '../help_topics.json'
import LocaleChanger from "./LocaleChanger";

export default {
  name: 'help-pages',
  props: ['disable_help_modal', 'close_help_modal', 'modal_first_time'],
  data: () => {
    return {
      allow_closing: false,
      disable: false,
      close_timeout_left: config.help_modal_close_timeout_seconds,
      close_timeout: config.help_modal_close_timeout_seconds,
      help_topics: help.help_topics,
      active_help_topic_key: help.help_topics[0]['key'],
      active_help_topic: help.help_topics[0],
    }
  },
  created() {
    if (!this.modal_first_time) {
      this.allow_closing = true;
    }
    else {
      let interval = setInterval(() => {
        this.close_timeout_left -= 1
        if (this.close_timeout_left <= 0) {
          this.allow_closing = true;
          clearInterval(interval)
        }
      }, 1000)
    }
  },
  watch: {
    active_help_topic_key: {
      handler(d) {
        this.active_help_topic = this.help_topics.find(ht => ht['key'] === d)
      }
    },
  },
  methods: {
    update_disable_value: function(value) {
      this.$emit('input', value)
    },
    next_help_topic: function() {
      let curr_index = this.help_topics.findIndex(ht => ht['key'] === this.active_help_topic_key)
      if (this.next_help_topic_available(curr_index)) {
        this.active_help_topic_key = this.help_topics[curr_index + 1]['key']
      }
    },
    prev_help_topic: function() {
      let curr_index = this.help_topics.findIndex(ht => ht['key'] === this.active_help_topic_key)
      if (this.prev_help_topic_available(curr_index)) {
        this.active_help_topic_key = this.help_topics[curr_index - 1]['key']
      }
    },
    next_help_topic_available: function(curr_index) {
      if (typeof curr_index === 'undefined') {
        curr_index = this.help_topics.findIndex(ht => ht['key'] === this.active_help_topic_key)
      }
      // console.log('next_help_topic_available', curr_index < this.help_topics.length - 1)
      return curr_index < this.help_topics.length - 1;
    },
    prev_help_topic_available: function(curr_index) {
      if (typeof curr_index === 'undefined') {
        curr_index = this.help_topics.findIndex(ht => ht['key'] === this.active_help_topic_key)
      }
      // console.log('prev_help_topic_available', curr_index > 0)
      return curr_index > 0;
    }
  },
  components: {
    LocaleChanger
  }
}
</script>

<style scoped lang="scss">

@import "css_vars";

.help-modal__modal {
  position: absolute;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: left;
  width: 900px;
  max-height: 100%;
  max-height: calc(100% - #{16px * 2});
  //overflow-y: auto;
  color: black;
  background: 0;
}

.help-modal__content {
  display: grid;

  grid-template-rows: auto auto auto auto auto;
  grid-template-columns: 1fr 2fr;
  grid-template-areas: "help_title help_title"
                        "navsidebar help_body"
                        "bottom bottom"
  ;
}


.help-modal__background {
  display: block;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255,0.7);
}

.help-modal__border-outer-radius {
  height: 100%;
  width: 100%;
  overflow: hidden;

  border-radius: $border-radius + $sidebar-border-thickness;
  //box-shadow: $default-box-shadow;
  z-index: 3;
  position: relative;
  background: $gradient;
}

.help-modal__border-inner-radius {
  height: 100%;
  width: 100%;
  padding: $sidebar-border-thickness;
  overflow: hidden;

  box-shadow: $default-box-shadow;
  position: relative;
}

.help-modal__border-inner-radius-background {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: $border-radius;
}

.help-modal__content {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
  padding: 16px;
}


.help-modal__list {
  grid-area: navsidebar;
  button {
    display: block;
  }
}
.help-modal__title {
  grid-area: help_title;
  margin-top: 0;
  text-align: center;
  color: $teal;
  font-size: 26px;
}

.help-modal__title--padded {
  padding-top: 2rem;
}

.locale-changer {
  position: absolute;
  top: $spacing / 2;
  left: $spacing / 2;
  padding: $spacing;
}

.help-modal__close {
  //grid-area: help_close;
  position: absolute;
  top: $spacing / 2;
  right: $spacing / 2;
  padding: $spacing;
  cursor: pointer;
  background: 0;
  border: 0;
  font-size: 26px;
  line-height: 1;
}

.help-modal__body {
  grid-area: help_body;
}

.help-modal__body-video {
  //grid-area: help_body;
}

.help-modal__body-video {
  margin-top: 16px;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
  iframe,
  iframe[style] {
    height: 100% !important;
    width: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

  }
}

.help-modal__nav {
 padding-top: $spacing;
  display: flex;
  justify-content: space-between;
  button {
    background: $teal;
    border: 0;
    padding: $spacing / 2 $spacing ($spacing / 2 - 2); // text not quite aligned to the bottom;
    border-radius: $border-radius;
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.25s ease;
    &:hover, &:focus {
      background: $blue;
    }
    &:disabled {
      background: $disabled-button;
      cursor: default;
    }
  }
}
.help-modal__close-dontshow {
  grid-area: bottom;
  display: flex;
  justify-content: space-between;
  padding-top: $spacing;
}

.help-modal__dont-show-again {
  display: flex;
  justify-content: flex-end;
  padding-left: $spacing;
  align-self: flex-end;
}

.help-modal__close-help-modal-help-text {
  display: block;
  opacity: 0.7;
  margin-top: $spacing / 2
}

.help-modal__close-help-modal-button {
  background: $teal;
  border: 0;
  padding: $spacing / 2 $spacing ($spacing / 2 - 2); // text not quite aligned to the bottom;
  border-radius: $border-radius;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.25s ease;
  &:hover, &:focus {
    background: $blue;
  }
  &:disabled {
    background: $disabled-button;
    cursor: default;
  }
}

.help-modal__list {
  .help-topic {
    background: 0;
    border: 0;
    width: 100%;
    text-align: left;
    padding: 8px 10px;
    cursor: pointer;
    transition: background 0.15s ease;
    &:not(:last-child) {
      border-bottom: 1px solid #cccccc;
    }
    &:hover, &:active {
      background-color: $blue-light;
    }
  }

  .help-topic--active {
    background: $teal-light;
  }
  padding-right: 16px;
}

.help-modal__body-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

</style>
