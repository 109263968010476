<template>
  <div class="institution-data__mini-block institution-data__mini-block--student-body">
    <h5 class="institution-data__mini-title">{{ $t('data-labels.block-titles.student_body') }}</h5>
    <div class="institution-data__mini-stats">
      <div class="institution-data__mini-stat"
           v-html="addGraphs(['enrolled_total',
           'fte_students', 'undergrad_enrollment',
           'graduate_enrollment', 'percent_pell'].map((s) => {
             return {'key': s, 'data': institution.student_body[s]}
           }), institution.ipeds, 'student_body')">
      </div>
      <div class="institution-data__mini-stat"
           v-html="addGraphs(['percent_race_white', 'percent_race_black', 'percent_race_asian', 'percent_race_hawaiian_pacific', 'percent_race_native', 'percent_race_hispanic', 'percent_race_two_or_more', 'percent_race_unknown'].map((s) => {
             return {'key': s, 'data': institution.student_body[s]}
           }), institution.ipeds, 'student_body', {'inline_labels': true, 'overall_label': $t('data-labels.student_body.race_ethnicity')})">
      </div>
      <div class="institution-data__mini-stat"
           v-html="addGraphs(['percent_adult'].map((s) => {
             return {'key': s, 'data': institution.student_body[s]}
           }), institution.ipeds, 'student_body')">
      </div>
    </div>
  </div>

</template>

<script>
import {addGraphs} from "../utilities";

export default {
  name: 'student-body-block',
  props: ['institution'],
  methods: {
    addGraphs: addGraphs,
  }
}
</script>
