<template>
  <div class="locale-changer">
    <select v-model="$root.$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ full_name[lang] }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data () {
    return { langs: ['en', 'es'], full_name: {'en': "English", 'es': "Español"} }
  }
}
</script>
