import Vue from 'vue'
import App from './App.vue'

import {updateScopeId} from "./utilities";
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.config.devtools = true

Vue.config.productionTip = false
Vue.use(VueAxios, axios)


Vue.directive('scope', {
  bind: updateScopeId,
});

new Vue({
  i18n,
  render: h => h(App)
}).$mount('#vue-container')
